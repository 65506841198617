export default [
  {
    path: "/banners",
    name: "banners",
    menu: {
      name: "Banners",
      icon: "fa fa-picture-o text-green",
    },
    meta: {
      title: "Show banners",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
    component: () => import("features/banners"),
  },
  {
    path: "/banners/:section_id",
    name: "banners-create",
    meta: {
      sidebar: "banners",
      title: "Add a new banner",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
    component: () => import("features/banners/pages/FormBanners"),
  },
  {
    path: "/banners/:section_id/:id",
    name: "banners-edit",
    component: () => import("features/banners/pages/FormBanners"),
    meta: {
      sidebar: "banners",
      title: "Update banner registration",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
  },
];
