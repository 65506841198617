import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon.scss";
import SidebarPlugin from "core/components/SidebarPlugin";
import NotificationPlugin from "core/components/NotificationPlugin";
import globalDirectives from "core/plugins/globalDirectives";
import globalComponents from "core/plugins/globalComponents";

export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(SidebarPlugin);
    Vue.use(NotificationPlugin);
    Vue.use(NotificationPlugin);
  },
};
