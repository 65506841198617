export default [
  {
    path: "/settings",
    redirect: "settings",
    component: () => import("features/settings"),
    menu: {
      name: "Settings",
      slug: "settings",
      icon: "fa fa-cog text-blue",
    },
    auth: {
      roles: ["administrator"],
    },
    children: [
      {
        path: "/settings",
        name: "settings",
        meta: {
          sidebar: "settings",
          title: "General settings",
        },
        component: () => import("features/settings/pages/General"),
      },
      {
        path: "/settings/plans",
        name: "setting-plans",
        meta: {
          sidebar: "settings",
          title: "Manage the plans",
        },
        component: () => import("features/settings/pages/Plans"),
      },
      {
        path: "/settings/plans/:id",
        name: "plans-edit",
        component: () => import("features/settings/pages/FormPlans"),
        meta: {
          sidebar: "settings",
          title: "Update registration a Plan",
          auth: {
            roles: ["designer", "administrator"],
          },
        },
      },
      {
        path: "/settings/pages",
        name: "setting-pages",
        meta: {
          sidebar: "settings",
          title: "Institutional pages of the site",
        },
        component: () => import("features/settings/pages/SitePages"),
      },
      {
        path: "/settings/shop",
        name: "setting-shop",
        meta: {
          sidebar: "settings",
          title: "Setting Shop Data",
        },
        component: () => import("features/settings/pages/ShopSetting"),
      },
      {
        path: "/settings/pages/create",
        name: "pages-create",
        meta: {
          sidebar: "settings",
          title: "Add a new Site Page",
        },
        component: () => import("features/settings/pages/FormPages"),
      },
      {
        path: "/settings/pages/:id",
        name: "pages-edit",
        component: () => import("features/settings/pages/FormPages"),
        meta: {
          sidebar: "settings",
          title: "Update registration a Site Page",
          auth: {
            roles: ["designer", "administrator"],
          },
        },
      },
      {
        path: "/settings/users",
        name: "setting-users",
        meta: {
          sidebar: "settings",
          title: "Manage the system users",
        },
        component: () => import("features/settings/pages/Users"),
      },
      {
        path: "/settings/users/:id",
        name: "users-edit",
        meta: {
          sidebar: "settings",
          title: "Update registration an User",
        },
        component: () => import("features/settings/pages/FormUsers"),
      },
      {
        path: "/settings/users/create",
        name: "users-create",
        meta: {
          sidebar: "settings",
          title: "Add a new User",
        },
        component: () => import("features/settings/pages/FormUsers"),
      },
    ],
  },
];
