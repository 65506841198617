export default [
  {
    path: "/companies",
    name: "companies",
    menu: {
      name: "Companies",
      icon: "fa fa-trademark text-warning",
    },
    meta: {
      title: "Show companies",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
    component: () => import("features/companies"),
  },
  {
    path: "/companies/list-changes/:id",
    name: "list_change_companies",
    meta: {
      sidebar: "companies",
      title: "History Change Status",
      auth: {
        roles: ["moderator", "administrator"],
      },
    },
    component: () => import("features/companies/pages/ListChangeStatus"),
  },
  {
    path: "/companies/create",
    name: "companies-create",
    meta: {
      sidebar: "companies",
      title: "Add a new brand",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
    component: () => import("features/companies/pages/FormCompanies"),
  },
  {
    path: "/companies/:id",
    name: "companies-edit",
    component: () => import("features/companies/pages/FormCompanies"),
    meta: {
      sidebar: "companies",
      title: "Update registration data",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
  },
  {
    path: "/companies/review/:id",
    name: "companies-review",
    component: () => import("features/companies/pages/FormCompanies"),
    meta: {
      sidebar: "companies",
      title: "Review Data Company",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
  },
];
