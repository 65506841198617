export default [
  {
    path: "/menus",
    name: "menus",
    menu: {
      name: "Menus",
      icon: "fa fa-bars text-yellow",
    },
    meta: {
      title: "Show menus",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
    component: () => import("features/menus"),
  },
  {
    path: "/menus/create",
    name: "menus-create",
    meta: {
      sidebar: "menus",
      title: "Add a new menu",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
    component: () => import("features/menus/pages/FormMenus"),
  },
  {
    path: "/menus/:id",
    name: "menus-edit",
    component: () => import("features/menus/pages/FormMenus"),
    meta: {
      sidebar: "menus",
      title: "Update registration data",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
  },
];
