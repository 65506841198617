export default [
  {
    path: "/categories",
    name: "categories",
    menu: {
      name: "Categories",
      icon: "fa fa-list text-blue",
    },
    meta: {
      title: "Show categories",
      auth: {
        roles: ["administrator"],
      },
    },
    component: () => import("features/categories"),
  },
  {
    path: "/categories/create",
    name: "categories-create",
    meta: {
      sidebar: "categories",
      title: "Add a new category",
      auth: {
        roles: ["administrator"],
      },
    },
    component: () => import("features/categories/pages/FormCategory"),
  },
  {
    path: "/categories/:id",
    name: "categories-edit",
    component: () => import("features/categories/pages/FormCategory"),
    meta: {
      sidebar: "categories",
      title: "Update registration data",
      auth: {
        roles: ["administrator"],
      },
    },
  },
];
