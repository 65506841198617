import Vue from "vue";
import VueRouter from "vue-router";

import ArgonDashboard from "plugins/argon-dashboard";
import router from "core/router";
import store from "core/store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import Datatable from "core/components/DataTable";
import vSelect from "vue-select";
import FileUpload from "core/components/FileUpload";
import CKEditor from "ckeditor4-vue";
import VueTheMask from "vue-the-mask";
import VueMask from "v-mask";
import Money from "v-money";
import auth, { config } from "plugins/auth";
import App from "@/App.vue";
import "plugins/axios";
import "plugins/vee-validate";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(ArgonDashboard);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CKEditor);
Vue.use(VueTheMask);
Vue.use(VueMask);
Vue.use(Money);
Vue.use(VueSweetalert2, {
  toast: true,
  position: "top-end",
  timer: 5000,
});

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router,
  },
  ...config,
});

// Global components
Vue.component("Datatable", Datatable);
Vue.component("FileUpload", FileUpload);
Vue.component("v-select", vSelect);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
