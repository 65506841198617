import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule],
  });
});

// Custom rules
extend("positive", {
  validate: (value) => value >= 0,
  message: "Only positive numbers",
});
extend("url", {
  validate: (value) =>
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    ),
  message: "Insert a valid url",
});

extend("one_image", {
  validate: (value) =>
    Array.isArray(value) && value.length === 1 && !!value[0]?.item && !!value[0]?.ext,
  message: "Insert one image",
});

extend("many_files", {
  validate: (value) => {
    console.log(value);
  },
  message: "Insert one or more files",
});

// Register globally
Vue.component("VeeProvider", ValidationProvider);
Vue.component("VeeObserver", ValidationObserver);
