<template>
  <div>
    <div class="row">
      <div class="col-sm-7 col-md-9">
        <div class="mb-4">
          <slot name="botaoCriar">
            <b-button class="mr-1" v-if="btnCreate" @click="create()" variant="primary" size="md"
              ><i class="fa fa-plus-circle" /> Create
            </b-button>
          </slot>

          <b-dropdown v-if="btnExport" variant="primary" size="sm">
            <template v-slot:button-content> <i class="fa fa-download" /> Export </template>
            <b-dropdown-item @click="exportar('excel')"
              ><i class="far fa-file-excel" /> Excel
            </b-dropdown-item>
            <b-dropdown-item @click="exportar('pdf')"
              ><i class="far fa-file-pdf" /> PDF</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="col-sm-5 col-md-3">
        <div class="mb-2">
          <input
            type="search"
            class="form-control form-control-md"
            placeholder="Search"
            v-model="search"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive mb-2">
      <table v-if="colunas.length > 0" :class="classe">
        <thead>
          <tr>
            <th v-for="(col, index) in colunas" :key="index" :width="col.width" nowrap>
              <span>{{ col["title"] }}</span>
              <a
                v-if="col.orderable"
                @click="ordernar(index)"
                class="fa fa-sort float-right filtro"
              ></a>
            </th>
          </tr>
        </thead>
        <tbody v-if="linhas">
          <tr v-for="(linha, index) in linhas" :key="index">
            <td
              class="align-middle td-datatable"
              v-for="(col, index) in colunas"
              :key="index"
              nowrap
            >
              <span v-if="col.data == 'action'">
                <slot :actions="linha[col.data]"></slot>
              </span>
              <span v-else>
                <slot :name="col.data" :linha="linha" :data="linha[col.data]">
                  <span v-html="linha[col.data]"></span>
                </slot>
              </span>
            </td>
          </tr>
          <tr v-if="linhas.length == 0">
            <td class="text-center" :colspan="colunas.length" nowrap>No records found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row px-3 justify-content-between">
      <span>
        {{ statusContagem }}
      </span>

      <b-pagination
        v-model="paginaAtual"
        :total-rows="recordsFiltered"
        :per-page="length"
        prev-text="<"
        next-text=">"
        align="right"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Datatable",
  props: {
    classe: {
      type: String,
      default: "table table-bordered table-striped",
    },
    btnCreate: {
      type: Boolean,
      default: true,
    },
    btnExport: {
      type: Boolean,
      default: true,
    },
    ordem: {
      type: String,
      default: "1",
    },
    url: {
      type: String,
      default: "",
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      colunas: [],
      linhas: [],
      urlDatatable: "",
      draw: 1,
      start: 0,
      length: 10,
      recordsFiltered: 0,
      recordsTotal: 0,
      search: "",
      paginaAtual: 1,
      order: {
        column: this.ordem,
        dir: "asc",
      },
    };
  },
  watch: {
    search() {
      this.start = 0;
      this.montaUrl();
    },
    paginaAtual(v) {
      this.start = (v - 1) * this.length;
      this.montaUrl();
    },
  },
  computed: {
    statusContagem() {
      let c = [];
      if (this.recordsFiltered === 0 || this.recordsTotal === 0) {
        c = [0, 0, 0];
      } else if (this.recordsFiltered === this.recordsTotal) {
        c = [
          this.start + 1,
          this.start + 1 >= this.recordsTotal
            ? this.linhas.length
            : this.start + this.linhas.length,
          this.recordsTotal,
        ];
      } else {
        c = [
          this.start + 1,
          this.start + 1 >= this.recordsTotal
            ? this.linhas.length
            : this.start + this.linhas.length,
          this.recordsFiltered,
          `(Filtered of ${this.recordsTotal} records)`,
        ];
      }

      return `Showing ${c[0]} to ${c[1]} of ${c[2]} records ${c[3] || ""}`;
    },
  },
  mounted() {
    this.getColunas();
  },
  methods: {
    requisicao() {
      // this.$insProgress.start()
      this.axios
        .get(this.urlDatatable)
        .then((res) => {
          this.linhas = res.data.data;
          this.recordsFiltered = res.data.recordsFiltered;
          this.recordsTotal = res.data.recordsTotal;
          // eslint-disable-next-line no-alert
          if (res.data.error) alert(res.data.error);
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err);
        });
    },
    create() {
      this.$emit("create");
    },
    ordernar(i) {
      this.order.column = i;
      this.order.dir = this.order.dir === "asc" ? "desc" : "asc";
      this.start = 0;
      this.montaUrl();
    },
    exportar(extend) {
      this.montaUrl(extend);
    },
    refresh() {
      this.montaUrl();
      this.$emit("refresh");
    },
    montaUrl(action = null) {
      const time = new Date().getTime();
      let urlTemp = `${this.url}?draw=${(this.draw += 1)}`;

      for (let i = 0; i < this.colunas.length; i += 1) {
        const c = this.colunas[i];
        urlTemp += `&columns[${i}][data]=${c.data}&columns[${i}][name]=${c.name}&columns[${i}][searchable]=${c.searchable}&columns[${i}][orderable]=${c.orderable}&columns[${i}][search][value]=&columns[${i}][search][regex]=false`;
      }

      let ft = "";
      Object.entries(this.filters).forEach(([key, value]) => {
        ft += `${key}=${value}&`;
      });

      if (action) {
        if (ft.length > 0) ft = `&${ft.substring(0, ft.length - 1)}`;
        urlTemp += `&order[0][column]=${this.order.column}&order[0][dir]=${this.order.dir}&start=${this.start}&length=${this.length}&search[value]=${this.search}&search[regex]=false&action=${action}${ft}`;
        this.urlDatatable = encodeURI(urlTemp);
        this.download(action);
      } else {
        urlTemp += `&order[0][column]=${this.order.column}&order[0][dir]=${this.order.dir}&start=${this.start}&length=${this.length}&search[value]=${this.search}&search[regex]=false&${ft}_=${time}`;
        this.urlDatatable = encodeURI(urlTemp);
        this.requisicao();
      }
    },
    download() {
      // this.$insProgress.start()
      this.axios({
        url: this.urlDatatable,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;

          const h = res.headers["content-disposition"];
          const arquivo = h
            .substring(h.indexOf("filename=") + 9)
            .split('"')
            .join("");

          link.setAttribute("download", arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err);
        });
    },
    getColunas() {
      this.axios
        .get(this.url)
        .then((res) => {
          this.colunas = res.data;
          this.montaUrl();
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err);
        });
    },
  },
};
</script>
<style scoped>
.filtro {
  cursor: pointer;
}

.td-datatable {
  padding: 5px 12px;
}
</style>
