import auth from "@websanova/vue-auth/src/v2.js";
import driverAuthBearer from "@websanova/vue-auth/src/drivers/auth/bearer.js";
import driverHttpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";

const config = {
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    notFoundRedirect: { name: "user-account" },
    stores: ["storage"],
    authRedirect: { path: "/" },
    tokenDefaultKey: "access_token",
    rolesKey: "roles",
    loginData: {
      url: "auth",
      method: "POST",
      redirect: "/",
      fetchUser: false,
      staySignedIn: true,
    },
    fetchData: {
      url: "auth/me",
      method: "GET",
      enabled: true,
    },
    parseUserData: (data) => ({ ...data.user, roles: data.roles }),
  },
};

export default auth;
export { config };
