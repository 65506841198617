<template>
  <li class="nav-item">
    <router-link
      :to="link.path"
      @click.native="linkClick"
      :class="['nav-link', { active: activeItemCheck() }]"
      :href="link.path"
    >
      <template>
        <i :class="link.icon"></i>
        <span class="nav-link-text">{{ link.name }}</span>
      </template>
    </router-link>
  </li>
</template>
<script>
export default {
  name: "sidebar-item",
  props: {
    link: {
      type: Object,
      default: () => ({
        name: "",
        icon: "",
        path: "",
        sidebar: "",
      }),
    },
  },
  inject: {
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  methods: {
    activeItemCheck() {
      return this.$route?.meta?.sidebar && this.$route?.meta?.sidebar === this.link?.sidebar;
    },
    linkClick() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
