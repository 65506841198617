export default [
  {
    path: "/brands",
    name: "brands",
    menu: {
      name: "Brands",
      icon: "fa fa-trademark text-warning",
    },
    meta: {
      title: "Show brands",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
    component: () => import("features/brands"),
  },
  {
    path: "/brands/create",
    name: "brands-create",
    meta: {
      sidebar: "brands",
      title: "Add a new brand",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
    component: () => import("features/brands/pages/FormBrands"),
  },
  {
    path: "/brands/:id",
    name: "brands-edit",
    component: () => import("features/brands/pages/FormBrands"),
    meta: {
      sidebar: "brands",
      title: "Update registration data",
      auth: {
        roles: ["designer", "administrator"],
      },
    },
  },
];
