<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="offset-lg-6 col-lg-6">
        <div class="copyright text-center text-lg-right text-muted">
          © {{ year }}
          <a :href="shopUrl" class="font-weight-bold ml-1" target="_blank">{{ appName }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
const appName = process.env.VUE_APP_NAME;
const shopUrl = process.env.VUE_APP_SHOP_URL;

export default {
  data() {
    return {
      year: new Date().getFullYear(),
      appName,
      shopUrl,
    };
  },
};
</script>
<style></style>
