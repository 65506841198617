<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground" :short-title="appName" :title="appName">
      <template slot="links">
        <template v-for="(item, index) in sidebarItems">
          <sidebar-item :link="{ ...item }" :key="index" />
        </template>
        <div class="border-top mt-5">
          <li class="nav-item">
            <a :href="shopUrl" class="nav-link" target="_blank">
              <i class="fa fa-external-link-square text-success"></i>
              Go to shop
            </a>
          </li>
          <sidebar-item
            @click.native.prevent="$auth.logout()"
            :link="{
              icon: 'fa fa-power-off text-danger',
              path: '#',
              name: 'Logout',
            }"
          />
        </div>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <base-nav class="navbar-top navbar-dark" id="navbar-main" :show-toggle-button="false" expand>
      </base-nav>
      <div @click="toggleSidebar">
        <div style="min-height: 100vh">
          <fade-transition :duration="200" origin="center top" mode="out-in">
            <!-- your content here -->
            <router-view></router-view>
          </fade-transition>
        </div>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
// eslint-disable-next-line import/no-cycle
import { routes } from "core/router";
import ContentFooter from "./ContentFooter.vue";

const shopUrl = process.env.VUE_APP_SHOP_URL;

const appName = process.env.VUE_APP_NAME;
export default {
  components: {
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", // vue|blue|orange|green|red|primary,
      appName,
      sidebarItems: [],
      shopUrl,
    };
  },
  mounted() {
    this.buildSidebar();
  },
  methods: {
    buildSidebar() {
      const filter = (_routes = []) =>
        _routes.reduce((prev, curr) => {
          if (
            curr.menu &&
            prev.every((p) => curr.path !== p.path) &&
            (this.$auth.check(curr?.meta?.auth?.roles ?? []) ||
              this.$auth.check("administrator") ||
              curr?.meta?.auth === true)
          ) {
            prev.push({
              ...(curr.menu ?? {}),
              sidebar: curr?.menu?.slug ?? curr.name,
              path: curr.path,
            });
          }
          if (curr.children) {
            return [
              ...prev,
              ...filter(curr.children).filter((child) => prev.every((p) => child.path !== p.path)),
            ];
          }
          return prev;
        }, []);
      this.sidebarItems = filter(routes);
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
