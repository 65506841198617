export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("features/dashboard"),
    menu: {
      name: "Dashboard",
      icon: "fa fa-tachometer text-info",
    },
    meta: {
      auth: true,
    },
  },
];
