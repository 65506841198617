import VueRouter from "vue-router";
import DashboardLayout from "layout/DashboardLayout";
import AuthLayout from "layout/AuthLayout";

import brandRoutes from "features/brands/routes";
import dasboardRoutes from "features/dashboard/routes";
import bannerRoutes from "features/banners/routes";
import menuRoutes from "features/menus/routes";
import settingsRoutes from "features/settings/routes";
import categoryRoutes from "features/categories/routes";
import companyRoutes from "features/companies/routes";

const routes = [
  {
    path: "/",
    redirect: "auth",
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "auth",
        component: () => import("features/auth"),
        meta: {
          auth: false,
        },
      },
    ],
  },
  {
    path: "/dashboard",
    redirect: "dashboard",
    component: DashboardLayout,
    meta: {
      auth: true,
    },
    children: [
      ...dasboardRoutes,
      ...categoryRoutes,
      ...brandRoutes,
      ...bannerRoutes,
      ...menuRoutes,
      ...settingsRoutes,
      ...companyRoutes,
    ],
  },
];

export default new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  routes,
});

export { routes };
